<template>
  <div ref="timePicker" v-hotkey="{ esc: close }" class="timePicker center relative">
    <div class="d-flex w100 align-center">
      <div type="button" class="w100">
        <input ref="button"   v-model="userTyped" type="text" placeholder="Search..." :style="inputStyling" data-lpignore="true" @keydown.prevent.down="down" @keydown.prevent.up="up" @keydown.prevent.enter="toggle" @keydown="keydown($event)" @click="toggle">
        <div ref="line" class="line" />
      </div>
      <div class="box center" :class="{ selected: show }" :style="boxStyling" @click="toggle">
        <s-icon v-show="clear" class="pa-5" height="20" color="grey">
          chevron-down
        </s-icon>
        <s-icon v-show="!clear" height="16" color="grey" class="pa-5" @click="clearInput">
          close
        </s-icon>
      </div>
    </div>
    <transition name="select">
      <div v-if="show" class="dropdown">
        <div v-for="(item, idx) in filteredList" :key="idx" :id="item == selected" :style="element" class="item space-between pointer" :class="{ selected: index == idx, selected1: index == idx }" @click="select(item)">
          <div>{{ item }}</div>
          <s-icon v-if="item == selected" class="mr-10" height="16" color="white">
            check
          </s-icon>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps(["height", "prefill", "modelValue"]);
const emit = defineEmits();

const selected = ref("");
const show = ref(false);
const userTyped = ref("");
const index = ref(0);
const filteredList = ref([]);
const enablePrefill = ref(false);

const store = useStore();
const timezones = computed(() => store.state.user.timezones);
const userTimezone = computed(() => store.state.user.userTimezone);

let line = ref(null);
let button = ref(null);
let timePicker = ref(null);

const onFocus = () => {
  userTyped.value = "";
  line.value.style.width = "100%";
  button.value.focus();
  show.value = true;
};

const listen = (event) => {
  let el = timePicker.value;
  if (!el) return;
  if (!el.contains(event.target)) {
    close();
  }
};

const clearInput = () => {
  userTyped.value = "";
};

const keydown = async (e) => {
  let ignore = [9, 13, 40, 37, 38, 39];
  if (e.keyCode == 9) return (show.value = false);
  if (ignore.includes(e.keyCode)) return;
  setTimeout(() => {
    show.value = true;
    index.value = 0;
  }, 0);

};

const down = () => {
  let idx = index.value;
  if (idx + 1 >= filteredList.value.length) return (index.value = 0);
  index.value = idx + 1;
  let element = document.getElementsByClassName("selected1")[0];
  if (!element) return;
  element.scrollIntoView({ inline: "nearest" });
};

const toggle = () => {
  emit("focused");
  if (show.value) {
    let secondKey = filteredList.value[index.value];
    select(secondKey);
    close();
  } else {
    onFocus();
    show.value = true;
  }
};

const up = () => {
  let top = filteredList.value.length - 1;
  if (index.value <= 0) return (index.value = top);
  index.value = index.value - 1;
  let element = document.getElementsByClassName("selected1")[0];
  if (!element) return;
  element.scrollIntoView({ block: "end", inline: "nearest" });
};

const select = (val) => {
  if (!val) return;
  setTimeout(() => {
    selected.value = val;
    userTyped.value = val;
    show.value = false;
    line.value.style.width = "0";
    emit("update:modelValue", val);
    emit("blur");
  });
};

const close = () => {
  if (show.value) {
    setTimeout(() => {
      if (show.value) {
        show.value = false;
        userTyped.value = selected.value;
        line.value.style.width = "0";
      }
    });
  }
};

const clear = computed(() => {
  return !(userTyped.value.length > 0 && show.value);
});

const inputStyling = computed(() => {
  let ob = {};
  if (props.height) ob.height = props.height + "px";
  return ob;
});

const boxStyling = computed(() => {
  let ob = {};
  if (props.height) {
    ob.height = props.height + "px";
    ob.minWidth = props.height + "px";
  }
  return ob;
});

const element = computed(() => {
  let ob = {};
  if (props.height) ob.height = props.height + "px";
  return ob;
});

watch(() => props.prefill, () => {
  if (!enablePrefill.value) return;
  if (!props.prefill) return;
  selected.value = props.prefill;
  userTyped.value = props.prefill;
  index.value = timezones.value.indexOf(props.prefill);
});

watch(userTyped, async (newValue) => {
  let typed = newValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  filteredList.value = timezones.value.filter((val) => {
    return val.toLowerCase().search(typed.toLowerCase()) !== -1;
  });
  let el = document.getElementById('true')
  let config = { block: "nearest", inline: "start" };
  if (el && el.length > 0) el[0].scrollIntoView(config);
});

onUnmounted(() => {
  document.body.removeEventListener("click", listen);
});

onMounted(async () => {
  selected.value = userTimezone.value;
  userTyped.value = userTimezone.value;
  index.value = timezones.value.indexOf(userTimezone.value);
  if (props.prefill) selected.value = props.prefill;
  if (props.prefill) userTyped.value = props.prefill;
  if (props.prefill) index.value = timezones.value.indexOf(props.prefill);
  emit("update:modelValue", userTimezone.value);
  document.body.addEventListener("click", listen);
  setTimeout(() => {
    enablePrefill.value = true;
  }, 200);
});
</script>

<style scoped>
.timePicker {
  width: 100%;
  position: relative;
}

.custom {
  width: 138px;
  height: 130px;
  background-color: #1a2430;
  font-family: Roboto;
  color: #a6acb1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  box-sizing: border-box;
}

.custom_title {
  font-size: 16px;
  font-style: italic;
}

.custom_input {
  margin-top: 9px;
  width: 50px;
  height: 25px;
  background-color: #303b49;
}

.custom_input:hover {
  background-color: #303b49c7;
}

.custom_input:focus {
  background-color: #303b49c7;
}

.custom_input_label {
  margin-left: 10px;
  font-size: 16px;
}

.box {
 position: absolute;
 right: 0px;
 
}

.dropdown {
  position: absolute;
  background-color: var(--shade3);
  z-index: 1;
  font-size: 16px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  width: 100%;
  overflow: auto;
  overflow: overlay;
  max-height: 250px;
  border-radius: 10px;
}

.dropdown>div {
  height: 40px;
  width: 133px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: var(--black);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.dropdown>div:hover {
  background-color: var(--shade1);
  color: var(--black);
}

input {
  height: 40px;
  font-size: 16px;
  color: var(--black);
  padding: 8px 19px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--shade3);
  border: 1.5px solid var(--shade4);
  border-radius: 15px;
  
}

.white input {
  background-color: var(--shade1);
  border: none;
}

.white  input:hover {
  background-color: var(--shade1);
}

input:hover {
  background-color: var(--shade3);
}

.selected_timezone {
  background-color: var(--shade3)
}

input:focus {
  background-color: var(--shade3);
  font-size: 16px;
  color: var(--black);
  outline: none;
}

.line {
  width: 0;
  height: 1px;
  background-color: var(--primary);
  transition: all ease 0.2s;
}



@media only screen and (max-width: 576px) {
  .dropdown {
    top: 42px;
  }
}
</style>