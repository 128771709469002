<template>
  <FocusLoop :auto-focus="false">
    <div ref="modal_page" class="meeting_modal_page center wrap">
      <div ref="modal_window" class="account_modal">
        <div class="row space-between">
          <div class="font-18 nowrap">
            {{ $t("modal.newMeeting.start") }}
          </div>
          <s-icon color="grey" @click="close">
            close
          </s-icon>
        </div>
        <div v-show="page1" class="body center d-col">
          <div class="font-18" v-if="!enableMCard">
            {{ $t("modal.newMeeting.one") }}
          </div>
          <s-dropdown v-if="!enableMCard" v-model="room" class="w70" :height="60" :items="roomsItems"  />
          <div class="font-18 pb-20">
            {{ enableMCard ? $t("modal.newMeeting.twoMcard") : $t("modal.newMeeting.two") }}
          </div>
          <radio v-model="option" class="px-20" />
          <s-text-field v-model="password" :placeholder="placeholder" :disabled="disabled" bar class="w70" />
          <s-btn class="green w50" @click="next">
            {{
              $t("modal.newMeeting.next")
            }}
          </s-btn>
        </div>

        <div v-show="!page1" class="">
          <div class="body center d-col">
            <div class="font-20">
              {{ $t("modal.newMeeting.ready") }}
            </div>
            <div class="row gap-10 px-50 linkText">
              <div class="font-18 opacity-60  linkBox text-center">
                {{ fullLink }}
              </div>
              <s-icon height="20" color="grey" class="pointer" @click="CopyToClipboard">
                content-copy
              </s-icon>
            </div>
            <div class="share opacity-60 font-14 text-center">
              {{ $t("modal.newMeeting.share") }}
            </div>
            <s-btn width="210" class="green" @click="join">
              {{ $t("modal.newMeeting.join") }}
            </s-btn>
          </div>
          <hr>
          <div class="center d-col  pt-20 invitation">
            <div class="font-18 mb-10">
              {{ $t("modal.newMeeting.invite") }}
            </div>
            <!-- <div class="font-16 missingText" :class="{ 'red--text': error, 'primary--text': !error }">
              {{ message }}
            </div> -->
            <div class="d-flex w80  row gap-20 sendBox">
              <s-text-field v-model="email" :error="message" :placeholder="$t('modal.newMeeting.enter')" block />
              <s-btn class="shade1 black--text" @click="invite">
                {{ $t("modal.newMeeting.send") }}
              </s-btn>
            </div>
          </div>
        </div>
      </div>

      <div ref="clipboard" style="color: black; background-color: #ffffff" class="clipboard">
        <div class="" style="color: var(--primary); font-size: 18px">
          SecurMeet
        </div>
        <div class="">
          <strong>{{ firstName }}</strong> has invited you to attend a SecurMeet
          Meeting
        </div>
        <div style="display: flex" class="">
          Link:
          <a :href="' https://' + meetServer + '/' + link">{{
            "https://" + meetServer + "/" + link
          }}</a>
        </div>
      </div>
    </div>
  </FocusLoop>
</template>

<script setup>
import radio from "../Schedule/Radio";
import { gsap } from "gsap";
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';




const { t } = useI18n();
const store = useStore();
const $toast = useToast();

const page1 = ref(true);
const password = ref("");
const disabled = ref(true);
const option = ref("one");
const room = ref(null);
const link = ref("");
const email = ref("");
const placeholder = ref(t("modal.newMeeting.password"));
const message = ref("");
const error = ref(false);
const modal_page = ref(null);
const modal_window = ref(null);

const close = () => {
  gsap.to(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => store.commit("modals/meetingModal", false),
  });
};

const next = () => {
  page1.value = false;
  if (enableMCard.value) return;
  for (let x of roomsItems.value) {
    if (x.id === room.value.id) {
      store.commit("rooms/setRoomName", x.title);
    }
  }
};

const invite = async () => {
  if (!validate()) return;
  const url = "/api/invite/" + link.value + "/" + email.value;
  const response = await store.dispatch("auth/invite", url);
  message.value = `${store.state.translation["modal.newMeeting.email_sent"]}${email.value}`;
  email.value = "";
  if (response.status === "ok") {
    message.value = message.value;
  }
};

const validate = () => {
  if (email.value === "") {
    message.value = t("modal.newMeeting.enter_valid_email");
    error.value = true;
    return false;
  }

  const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regexp.test(email.value.toLowerCase())) {
    message.value = t("modal.newMeeting.enter_valid_email");
    error.value = true;
    return false;
  }

  message.value = "";
  error.value = false;
  return true;
};

const generatePassword = () => {
  let length = 16,
    charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
    retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const generateLink = async () => {
  let liveRoom = !enableMCard.value ? room.value?.id : rooms.value[0]?.id
  if (!liveRoom) return;
  const url = "/api/room/" + liveRoom;
  const response = await store.dispatch("auth/room", url);
  link.value = response.url;
  store.commit("user/activeRoom", response.url);
};

const join = () => {
  let liveRoom = !enableMCard.value ? room.value?.id : rooms.value[0].id
  store.commit("modals/meetingModal", false);
  store.dispatch('rooms/startMeeting', liveRoom);
  store.dispatch('user/startMeeting', liveRoom);
  window.open(fullLink.value);
};

const CopyToClipboard = () => {
  navigator.clipboard.writeText(fullLink.value);
  $toast.success('Link Copied to clip');
};

const firstName = computed(() => store.state.user.firstName);
const meetServer = computed(() => store.state.user.meetServer);
const rooms = computed(() => store.state.rooms.rooms);
const fullLink = computed(() => "https://" + meetServer.value + "/" + link.value);
const userEmail = computed(() => store.state.user.email);
const enableMCard = computed(() => store.state.user.enableMCard);
const roomsItems = computed(() => {
  let data = rooms.value;
  for (let x of data) {
    x.display = x.title;
  }
  return data.filter((el) => !el.isDeleted);
});

const passwordInit = () => {
  if (option.value === "one") {
    password.value = "";
    disabled.value = true;
    placeholder.value = t("modal.newMeeting.not_protected");
  }
  if (option.value === "two") {
    password.value = "";
    disabled.value = false;
    placeholder.value = t("modal.newMeeting.enter_password");
  }
  if (option.value === "three") {
    password.value = generatePassword();
    disabled.value = true;
  }
}

watch(option, (val) => {
  passwordInit()

});

watch(room, () => {
  generateLink();
});

onMounted(() => {
  generateLink();
  passwordInit();
  gsap.from(modal_page.value, {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });

  gsap.from(modal_window.value, {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
});


</script>

<style scoped>
.meeting_modal_page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 101vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 100%;
  padding: 30px;
  min-height: 475px;
  max-width: 600px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade1);
  border-radius: 10px;
  overflow: hidden;
}

.header {
  display: grid;
  grid-template-columns: 20px auto 20px;
  font-size: 20px;
}

.linkBox {
  word-break: break-word;
}

.body {
  padding-top: 60px;
  padding-bottom: 30px;
  grid-row-gap: 30px;
  min-height: 202px;
}

.share {
  max-width: 331px;
}

.clipboard {
  position: absolute;
  left: 9999999px;
}

@media only screen and (max-width: 576px) {
  .linkText {
    padding: 0;
  }

  .sendBox {
    gap: 0;
    flex-direction: column;
  }
}
</style>
