<template>
  <div ref="datePickerContainer">
    <div class="datepicker noSelect">
      <div class="d-flex space-between capital">
        <div class="pl-10">
          {{ date.locale(lang).format("MMM") }} {{ date.format("YYYY") }}
        </div>
        <section class="center">
          <button class="mr-15 center iconFilter" @click="decrement">
            <s-icon color="white">
              chevron-left
            </s-icon>
          </button>
          <button class="center iconFilter" @click="increment">
            <s-icon color="white">
              chevron-right
            </s-icon>
          </button>
        </section>
      </div>
      <div class="headings capital">
        <div v-for="item in daysOfWeek" :key="item.idx">
          {{ item }}
        </div>
      </div>
      <div class="grid-container">
        <div v-for="item in monthRange" :key="item.idx" class="center grid-item">
          <button v-if="item.month() + 1 == date.format('M')" class="opacity-60 today-oval center oval" :class="{
            green: today == item.format('DD/MMM/YYYY'),
            selected:
              absDate.format('MMM D, YYYY') == item.format('MMM D, YYYY'),
          }" @click="selectDate(item)">
            {{ item.format("D") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import dayjs from "dayjs";
import localData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useStore } from 'vuex';

dayjs.extend(localData);
dayjs.extend(localizedFormat);

const store = useStore();
const emit = defineEmits();

const datePickerContainer = ref(null);
const props = defineProps(["height", "prefill", "noedit"]);
const date = ref(dayjs());
const monthRange = ref([]);
const selected = ref(null);
const today = ref(null);
const absDate = ref(null);
const lang = computed(() => store.state.user.lang);
const urlDate = computed(() => store.state.calendar.date);

const daysOfWeek = computed(() => {
  return dayjs().locale(lang.value).localeData().weekdaysMin();
});

watch(props.prefill, (newPrefill) => {
  if (newPrefill) {
    selected.value = dayjs(newPrefill);
    date.value = dayjs(newPrefill);
    absDate.value = dayjs(newPrefill);
  }
});

watch(urlDate, (val) => {
  selected.value = dayjs(val);
  date.value = dayjs(val);
  absDate.value = dayjs(val);
  generateCal(val);
});

const generateCal = (targetDate) => {
  monthRange.value = [];
  let day = targetDate.startOf("month").startOf("week");
  for (let x = 0; x < 42; x++) {
    monthRange.value.push(day.add(x, "day"));
  }
};

const increment = () => {
  date.value = date.value.add(1, "month");
  generateCal(date.value);
};

const decrement = () => {
  date.value = date.value.subtract(1, "month");
  generateCal(date.value);
};

const wait = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const selectDate = async (val) => {
  selected.value = val.locale(lang.value).format("ll");
  absDate.value = val;
  emit("changeDate", val);
  await wait();
  let el = document.querySelector('.selected');
  if (el) el.focus();
};

const init = () => {
  date.value = dayjs();
  today.value = dayjs().format("DD/MMM/YYYY");
  selected.value = dayjs().locale(lang.value).format("ll");
  absDate.value = dayjs();

  if (props.prefill) {
    selected.value = dayjs(props.prefill);
    selected.value = selected.value.locale(lang.value).format("ll");
    date.value = dayjs(props.prefill);
    absDate.value = dayjs(props.prefill);
  }
  generateCal(date.value);

  if (!props.noedit) return;
  context.emit("changeDate", dayjs(absDate.value));
};

onMounted(() => {
  init()
})
</script>

<style scoped>
.datePickerContainer {
  width: 100%;
}

.datepicker {
  width: 240px;
  /* height: 290px; */
  padding: 15px;
  padding-bottom: 5px;
  box-sizing: border-box;
  border-radius: 10px;
}

.headings {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  opacity: 0.6;
  font-size: 10px;
  margin-top: 15px;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 205px;
  margin-top: 10px;
}

.grid-item {
  aspect-ratio: 1;
}

.line {
  width: 0;
  height: 1px;
  background-color: var(--primary);
  transition: all ease 0.2s;
}
.oval{
  color: var(--black)
}


.oval:hover,
.oval:focus {
  background-color: var(--shade0);
  border-radius: 50%;
  height: 80%;
  width: 80%;
  opacity: 1;
  outline: none;
}

.selected {
  background-color: #3a4b5a;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 50%;
  height: 80%;
  width: 80%;
  font-weight: 400;
}

.iconFilter:focus,
.iconFilter:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
  border-radius: 50%;
  outline: none;
}

.today-oval {
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

@media only screen and (max-width: 576px) {}
</style>