<template>
  <FocusLoop>
    <div class="modal_page center">
      <form ref="account_modal" class="account_modal d-flex d-col space-between" @submit.prevent="post">
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ $t("modal.timezone.title") }}
          </div>

          <button type="button" class="center" @click="closeModal">
            <s-icon height="16" color="white">
              close
            </s-icon>
          </button>
        </div>
        <div class="center mt-10 missingText red--text font-14">
          {{ message }}
        </div>
        <div>
          <s-timezone v-model="timezone" :prefill="timezones" height="40" />
        </div>

        <div class="d-flex justify-end mt-40">
          <s-btn type="button"  class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit"  class="green">
            {{
              $t("modal.save")
            }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { gsap } from "gsap";
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';

const store = useStore();

const name = ref("");
const selected = ref(1);
const message = ref("");
const timezone = ref("");

const edit = computed(() => store.state.attendees.edit);
const timezones = computed(() => store.state.schedule.timezones);
const action = computed(() => edit.value ? "Edit" : "Create");

const post = () => {
    store.commit("schedule/setTimezone", timezone.value);
    closeModal();
};

const select = (val) => {
    selected.value = val;
};

const closeModal = () => {
    gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });
    gsap.to(".account_modal", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => store.commit("modals/timezoneModal", false),
    });
};

onMounted(() => {
    if (edit.value) {
        name.value = edit.value.title;
        selected.value = edit.value.color;
    }

    gsap.from(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });

    gsap.from(".account_modal", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
    });
});
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 40px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade3);
  border-radius: 15px;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.color {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.color:hover {
  box-shadow: 0 0 12px var(--primary);
}

.active {
  box-shadow: 0 0 20px var(--primary);
  border: 1px solid rgb(255, 255, 255);
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>