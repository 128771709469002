<template>
  <img :src="iconName" :style="styling" :class="color + '_svg'" alt="">
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps(['width', 'height', 'color']);

const styling = computed(() => ({
  minHeight: props.height + "px",
  minWidth: props.width + "px",
  height: props.height + "px",
  width: props.width + "px"
}));

const iconName = computed(() => {
  if (slots.default && slots.default().length > 0) {
    return "/img/icons/" + slots.default()[0].children.trim() + ".svg";
  }
  return "";
});
console.log( props.color)
const slots = defineSlots();
</script>

<style>
.white_svg {
  filter: invert(100%) sepia(98%) saturate(8%) hue-rotate(155deg) brightness(102%) contrast(106%);
}

.green_svg {
  filter: invert(32%) sepia(100%) saturate(853%) hue-rotate(178deg) brightness(93%) contrast(88%);
}

.primary_svg {
  filter: invert(32%) sepia(100%) saturate(853%) hue-rotate(178deg) brightness(93%) contrast(88%);
}

.red_svg {
  filter: invert(34%) sepia(76%) saturate(1102%) hue-rotate(326deg) brightness(102%) contrast(81%);
}

.grey_svg {
  filter: invert(76%) sepia(10%) saturate(156%) hue-rotate(165deg) brightness(89%) contrast(91%);
}

.blue_svg {
  filter: invert(32%) sepia(100%) saturate(853%) hue-rotate(178deg) brightness(93%) contrast(88%);
}

.dark_svg {
  filter: invert(15%) sepia(31%) saturate(753%) hue-rotate(178deg) brightness(94%) contrast(85%);
}
</style>
