<template>
  <button ref="buttonRef" class="button center uppercase relative px-15 b-box" :disabled="disabled || loading" :style="styling" :type="type">
    <pulse-loader :loading="load" :color="'var(--primary)'" size="10px" />
    <slot v-if="!load" />
  </button>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps([
  "width",
  "height",
  "color",
  "opacity",
  "font",
  "block",
  "min",
  "disabled",
  "icon",
  "outlined",
  "type",
  "loading",
]);
const buttonRef = ref(null);

const load = computed(() => {
  let con1 = props.loading == true;
  let con2 = props.loading !== "";
  let con3 = typeof props.loading !== "undefined";
  return con1 && con2 && con3;
});

const styling = computed(() => {
  let ob = {
    height: props.height + "px",
    minWidth: props.width + "px",
    backgroundColor: props.color,
    opacity: props.opacity,
    fontSize: props.font + "px",
  };
  if (
    props.block == true &&
    props.block !== "" &&
    typeof props.block !== "undefined"
  ) {
    ob.width = "auto";
  }

  if (
    props.block == false &&
    props.block == "" &&
    typeof props.block !== "undefined"
  ) {
    ob.width = "100%";
  }
  if (props.outlined == "") ob.border = "1px solid black";
  if (props.outlined == "") ob.color = "black";
  if (props.outlined == "") ob.backgroundColor = "transparent";

  if (props.min == "" || props.min == "true") ob.minWidth = 0;
  return ob;
});

const onFocus = () => {
  buttonRef.value.focus();
};

defineExpose({ onFocus })
</script>



<style scoped lang="less">
.iconLayout {
  display: grid;
  grid-template-columns: 25px 1fr 10px;
  padding-left: 10px;
}

.slot {
  margin: 20px;
}

.button {
  min-width: 150px;
  height: 60px;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  border-radius: 15px;
  transition: all 0.3s ease;
  border: 1.5px solid var(--shade4);
}


// .button:focus {
//   outline: 3px solid #425464;
// }

.button:hover {
  background-color: var(--shade4);
}

.button:disabled {
  min-width: 95px;
  background-color: #444954;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  pointer-events: none;
}

.button:disabled:hover {
  background-color: #425464;
}

.red {
  background-color: #9e3d3d;
}

.red:hover {
  background-color: #df5151;
}

.primaryHover:hover {
  background-color: var(--primary);
  filter: brightness(1.1)
}

.green {
  background-color: var(--primary);
  filter: brightness(1.1);
}

.green:hover {
  background-color:  var(--primary);
  filter: brightness(1.2);
}

.green:focus {
  outline: 2px solid  var(--primary);
}

.red:focus {
  outline: 2px solid #df5151;
}

.outline {
  color: var(--black);
}
</style>
