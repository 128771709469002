<template>
  <div ref="datePickerContainer" class="datePickerContainer relative">
    <section class="d-flex w100">
      <div class="col align-start w100">
        <label class="black--text">Date</label>
        <div class="row w100 relative">
          <input ref="button" v-model="selected" maxlength="0" type="text" :style="inputStyling" :disabled="!noedit" data-lpignore="true" class="capital" @keydown.delete="(e) => e.preventDefault()" @click="showPicker" @keydown.enter="showPicker" @keydown.space="showPicker" @keydown.esc="onBlur">
          <button v-if="noedit" tabindex="-1" type="button" class="absolute p-right p-center-y px-15 center" :style="boxStyling" @click="showPicker">
            <s-icon height="16" color="grey">
              calendar-week
            </s-icon>
          </button>
        </div>
      </div>

    </section>

    <transition name="dropdown">
      <div v-if="show" v-hotkey="{ esc: onBlur }" class="datepicker noSelect">
        <FocusLoop>
          <div class="d-flex space-between capital">
            <div class="row align-center">
              {{ dayjs(date).locale(lang).format("MMM") }} {{ dayjs(date).format("YYYY") }}
            </div>
            <div class="row gap-10">
              <button type="button" class="center chevron ah-30 ratio" @click="decrement">
                <s-icon color="black">
                  chevron-left
                </s-icon>
              </button>
              <button type="button" class="center chevron ah-30 ratio" @click="increment">
                <s-icon color="black">
                  chevron-right
                </s-icon>
              </button>
            </div>
          </div>
          <div class="headings capital">
            <div v-for="item in daysOfWeek" :key="item.idx">
              {{ item }}
            </div>
          </div>
          <div class="grid-container">
            <div v-for="item in monthRange" :key="item.idx" class="center">
              <button v-if="item.month() + 1 == date.format('M')" class="opacity-60 today-oval center oval" type="button" :class="{
                shade4: today == item.format('DD/MMM/YYYY'),
                selected:
                  absDate.format('MMM D, YYYY') == item.format('MMM D, YYYY'),
              }" @click="selectDate(item)">
                {{ item.format("D") }}
              </button>
            </div>
          </div>
        </FocusLoop>
      </div>
    </transition>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import localData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
const { height, prefill, noedit } = defineProps(["height", "prefill", "noedit"]);

const emit = defineEmits();

dayjs.extend(localData);
dayjs.extend(localizedFormat);

const store = useStore();
const router = useRouter();

const lang = computed(() => store.state.user.lang);
const daysOfWeek = computed(() => dayjs().locale(lang.value).localeData().weekdaysMin());


let show = ref(false);
let absDate = ref(null);
let today = ref(null);
let button = ref(null);
let date = ref(null);
let selected = ref(null);
let datePickerContainer = ref(null);
let monthRange = ref([]);

const inputStyling = computed(() => {
  let ob = {};
  if (height) ob.height = height + "px";
  if (!noedit) ob.backgroundColor = "#00000000";
  if (show.value) ob.backgroundColor = "var(--white)";
  return ob;
});

const boxStyling = computed(() => {
  let ob = {};
  if (height) ob.height = height + "px";
  if (height) ob.minWidth = height + "px";
  if (show.value) ob.backgroundColor = "#000000";
  return ob;
});

const generateCal = (date) => {
  monthRange.value = [];
  let day = date.startOf("month").startOf("week");
  for (let x = 0; x < 42; x++) {
    monthRange.value.push(day.add(x, "day"));
  }
};

const increment = () => {
  date.value = date.value.add(1, "month");
  generateCal(date.value);
};

const decrement = () => {
  date.value = date.value.subtract(1, "month");
  generateCal(date.value);
};

const selectDate = (val) => {
  selected.value = val.locale(lang.value).format("ll");
  absDate.value = val;
  emit("changeDate", val);
  show.value = false;
  onBlur();
  button.value.focus();
};

const showPicker = () => {
  if (!noedit) return;
  show.value = !show.value;

};

const listen = (event) => {
  const el = datePickerContainer.value;
  if (!el.contains(event.target)) {
    show.value = false;
    onBlur();
  }
};

const onBlur = () => {
  show.value = false;
};

const init = () => {
  date.value = dayjs();
  today.value = dayjs().format("DD/MMM/YYYY");
  selected.value = dayjs().locale(lang.value).format("ll");
  absDate.value = dayjs();
  if (prefill) {
    selected.value = dayjs(prefill, "MMM D, YYYY");
    selected.value = selected.value.locale(lang.value).format("ll");
    date.value = dayjs(prefill, "MMM D, YYYY");
    absDate.value = dayjs(prefill, "MMM D, YYYY");
  }
  generateCal(date.value);

  if (!noedit) return;
  emit("changeDate", dayjs(absDate.value));
};

watch(router.currentRoute, () => {
  init();
});

watch(lang, () => {
  init();
});

onMounted(() => {
  document.body.addEventListener("click", listen);
  init();
});

onUnmounted(() => {
  document.body.removeEventListener("click", listen);
});


</script>


<style scoped>
.datePickerContainer {
  width: 100%;
}

.datepicker {
  width: 290px;
  height: 320px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--white);
  color: var(--black);
  padding: 15px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  right: 0;
  border-radius: 10px;
}

.headings {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  opacity: 0.6;
  font-size: 10px;
  margin-top: 15px;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 235px;
  margin-top: 10px;
}

.line {
  width: 0;
  height: 1px;
  background-color: var(--primary);
  transition: all ease 0.2s;
}

.today-oval {
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

.oval:hover,
.oval:focus {
  background-color: var(--shade4);
  border-radius: 50%;
  border-radius: 50%;
  height: 80%;
  width: 80%;
  opacity: 1;
}

.chevron:focus,
.chevron:active {
  background-color: var(--shade3);
  border-radius: 50%;
}

.selected {
  background-color: var(--shade3);
  border: 1px solid var(--primary);
  color: var(--black);
  border-radius: 50%;
  height: 80%;
  width: 80%;
  font-weight: 600;
}

input {
  background-color: var(--shade3);
  border: 1.5px solid var(--shade4);
  font-size: 14px;
  color: var(--black);
  padding: 12px 19px 12px 19px;
  width: 100%;
  box-sizing: border-box;
  caret-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
  border-radius: 15px;
  transition: all 0.3s ease;
}

input:disabled {
  cursor: default;
}

input:hover {
  background-color: var(--shade3);
}

input:focus {
  background-color: var(--shade1);
  color: var(--black);
  font-size: 14px;
  outline: none;
}

input::selection {
  background: none;
}

button {
  color: var(--black);
}

@media only screen and (max-width: 576px) {
  .datepicker {
    position: fixed;
    top: calc(100vh - 50%);
    left: calc(100vw - 50%);
    transform: translate(-50%, -50%);
  }
}
</style>